import { getReq, postReq, putReq } from "@services/Api"

export const students = {
    getById: async (id) => { return await getReq('Student', '/Student', `/student/${id}`, true) },
    getFile: async (name) => { return await getReq('Student', '/StudentCourse', `/file/${name}`, true) },
    save: async (body) => { return await postReq('Student', '/Student', `student`, body, true) },
    update: async (body, id) => { return await putReq('Student', '/Student', `student/${id}`, body, true) },
    status: async (id, status) => { return await getReq('Student', '/Student', `status/${id}/${status}`, true) },
    list: async (search, status, page, take) => { return await getReq('Student', '/Student', `students?PageNumber=${page}&PageSize=${take}${search.length > 0 ? `&Search=${search}` : ''}${status.length > 0 ? `&Status=${status}` : ''}`, true) },
}

export const chat = {
    getSessionTasks: async (sessionResourcesId, userId) => { return await getReq('Chat', `/Chat/getTasks/${sessionResourcesId}/${userId}`, true) },
    getSessionChatContacts: async (sessionResourcesId) => { return await getReq('Chat', `/Chat/getChatContacts/${sessionResourcesId}`, true) },
    selectSessionChat: async (id) => { return await getReq('Chat', `/Chat/selectChat/${id}`, true) },
    sendSessionMsg: async (body) => { return await postReq('Chat', `/Chat/sendMsg`, body, true) },
}

export const courses = {
    list: async (studentId, search, status, page, take) => { return await getReq('Courses', `/StudentCourse/courses?studentId=${studentId}&PageNumber=${page}&PageSize=${take}${search.length > 0 ? `&Search=${search}` : ''}${status.length > 0 ? `&Status=${status}` : ''}`, true) },
    getById: async (id) => { return await getReq('Course', `/StudentCourse/course/${id}`, true) },
    calendar: async (id) => { return await getReq('Course', `/StudentCourse/calendar/${id}`, true) },
    getFile: async (name) => { return await getReq('Course', `/StudentCourse/file/${name}`, true) },
    sondagens: async (sessionResourcesId, studentCourseId) => { return await getReq('Course', `/StudentCourse/sondagens/${sessionResourcesId}/${studentCourseId}`, true) },
    sondagens_save: async (body) => { return await postReq('Course', `/StudentCourse/sondagens`, body, true) },
    resource: async (id) => { return await getReq('Course', `/StudentCourse/resource/${id}`, true) },
    comment: async (body) => { return await postReq('Course', `/StudentCourse/comment`, body, true) },
    comments: async (studentCourseId, sessionResourcesId) => { return await getReq('Course', `/StudentCourse/comments/${studentCourseId}/${sessionResourcesId}`, true) },
    save_attachments_trabalho: async (studentCourseId, sessionResourcesId, body) => { return await postReq('Course', `/StudentCourse/attachments-trabalho/${studentCourseId}/${sessionResourcesId}`, body, true) },
    all_attachments_trabalho: async (studentCourseId, sessionResourcesId) => { return await getReq('Course', `/StudentCourse/attachments-trabalho/${studentCourseId}/${sessionResourcesId}`, true) },
    student_teste: async (sessionResourcesId, studentCourseId) => { return await getReq('Course', `/StudentCourse/student-teste/${sessionResourcesId}/${studentCourseId}`, true) },
    save_teste: async (body) => { return await postReq('Course', `/StudentCourse/student-teste`, body, true) },
    finalizar_teste: async (body) => { return await postReq('Course', `/StudentCourse/finalizar-student-teste`, body, true) },
}
