// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('pt-Br', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('pt-Br', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getToken = () => JSON.parse(localStorage.getItem('accessToken'))

export const getRefreshToken = () => JSON.parse(localStorage.getItem('refreshToken'))

export const downloadFilesBase64 = (base, filename) => {
  const downloadLink = document.createElement("a")
  downloadLink.href = base
  downloadLink.download = filename
  downloadLink.click()
}



export const obterRefreshToken = async () => {
  try {
    const params = {
      refreshToken: JSON.parse(localStorage.getItem('refreshToken'))
    }
    const url_completa = `${process.env.REACT_APP_API_URL}/auth/refresh-token`
    const headers = setHeaders('application/json, text/plain')
    const reqParams = {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    }
    const resp = await fetch(url_completa, reqParams)
    const json = await resp.json()
    if (json) {
      if (json?.mensagens) {
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('sessoes')
        window.location.href = '/login'
        return
      }
      localStorage.setItem('accessToken', JSON.stringify(json.accessToken))
      localStorage.setItem('refreshToken', JSON.stringify(json.refreshToken))
    } else {
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('sessoes')
      window.location.href = '/login'
    }
    // return json
  } catch (error) {
    localStorage.removeItem('userData')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('sessoes')
    window.location.href = '/login'
    console.log('error', error)
  }
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

export const handleLogin = data => {
  localStorage.setItem('userData', JSON.stringify(data.userData))
  localStorage.setItem('accessToken', JSON.stringify(data.accessToken))
  localStorage.setItem('refreshToken', JSON.stringify(data.refreshToken))
}

export const handleLogout = () => {
  localStorage.removeItem('userData')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('sessoes')
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export function removerCaracteres(valor) {
  return valor
    ? String(valor)
        .replace(/[^\w\s]/gi, "")
        .trim()
    : valor;
}

export function formatarValorSalvar(valor) {
  return valor
    ? parseFloat(
        String(valor)
          ?.replace(/[^0-9,.]/g, "")
          ?.replace(".", "")
          ?.replace(",", ".")
          ?.trim()
      )
    : valor;
}
 
export function formatCurrency(valor, casasDecimais = 2) {
  if (!valor) return "";
  return valor.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: casasDecimais
  });
}
 
export function formataPercentual(valor, casasDecimais = 2) {
  return `${valor?.toLocaleString("pt-br", {
    currency: "BRL",
    maximumFractionDigits: casasDecimais
  })}%`
}
 
export function formataTelefone(telefone) {
  if (!telefone) return "";
  telefone = telefone.replace(/\D/g, "");
  telefone = telefone.replace(/^(\d{2})(\d{2})(\d)/g, "+$1 ($2) $3");
  telefone = telefone.replace(/(\d)(\d{4})$/, "$1-$2");
  return telefone;
}
 
export function formataCnpj(cnpj) {
  if (!cnpj) return "";
  cnpj = cnpj.replace(/[^\d]/g, "");
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}
 
export function formataCpf(cpf) {
  if (!cpf) return "";
  return cpf
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

export function formataCep(cep) {
  if (!cep) return "";
  return cep
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
}
 
export function formataValorSanitizado(valor) {
  return valor ? String(valor).replace(",", ".") : valor;
}
 
export function formatarValor(num) {
 
  const value = Math.abs(num);
 
    switch (true) {
      case (value > 999 && value < 999999):
        return Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k';
      case (value > 999999 && value < 999999999):
        return Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'M';
      case (value > 999999999):
        return Math.sign(num)*((Math.abs(num)/1000000000).toFixed(1)) + 'Bi';
      default:
        return Math.sign(num) * Math.abs(num);
    }
}

export function resetCnpjFormat(cnpj) {
  return cnpj?.replaceAll('.', '').replace('/', '').replace('-','')
}

export function resetCpfFormat(cpf) {
  return cpf?.replaceAll('.', '').replace('-','')
}

export function resetCifrao(cpf) {
  return cpf.replaceAll('R$ ', '').replace('R$','').replace(',','.')
}

export function resetPhoneFormat(phone) {
  return phone?.replace('(', '').replace(') ', '').replace('-','')
}

export function resetCepFormat(cep) {
  return cep?.replace('.', '').replace('-', '')
}

export function formatBigNames(name) {
  // console.log(name.splice(0, 30))
  if (name.length > 28) {
    return name.slice(0, 28).concat('...')
  } 
  return name
}

export const phoneMasks = { 
  "AC": "+247-####", 
  "AD": "+376-###-###", 
  "AE": "+971-5#-###-####", 
  "AE": "+971-#-###-####", 
  "AF": "+93-##-###-####", 
  "AG": "+1(268)###-####", 
  "AI": "+1(264)###-####", 
  "AL": "+355(###)###-###", 
  "AM": "+374-##-###-###", 
  "AN": "+599-###-####", 
  "AN": "+599-###-####", 
  "AN": "+599-9###-####", 
  "AO": "+244(###)###-###", 
  "AQ": "+672-1##-###", 
  "AR": "+54(###)###-####", 
  "AS": "+1(684)###-####", 
  "AT": "+43(###)###-####", 
  "AU": "+61-#-####-####", 
  "AW": "+297-###-####", 
  "AZ": "+994-##-###-##-##", 
  "BA": "+387-##-#####", 
  "BA": "+387-##-####", 
  "BB": "+1(246)###-####", 
  "BD": "+880-##-###-###", 
  "BE": "+32(###)###-###", 
  "BF": "+226-##-##-####", 
  "BG": "+359(###)###-###", 
  "BH": "+973-####-####", 
  "BI": "+257-##-##-####", 
  "BJ": "+229-##-##-####", 
  "BM": "+1(441)###-####", 
  "BN": "+673-###-####", 
  "BO": "+591-#-###-####", 
  "BR": "+55(##)####-####", 
  "BR": "+55(##)7###-####", 
  "BR": "+55(##)9####-####", 
  "BS": "+1(242)###-####", 
  "BT": "+975-17-###-###", 
  "BT": "+975-#-###-###", 
  "BW": "+267-##-###-###", 
  "BY": "+375(##)###-##-##", 
  "BZ": "+501-###-####", 
  "CA": "+1(###)###-####", 
  "CD": "+243(###)###-###", 
  "CF": "+236-##-##-####", 
  "CG": "+242-##-###-####", 
  "CH": "+41-##-###-####", 
  "CI": "+225-##-###-###", 
  "CK": "+682-##-###", 
  "CL": "+56-#-####-####", 
  "CM": "+237-####-####", 
  "CN": "+86(###)####-####", 
  "CN": "+86(###)####-###", 
  "CN": "+86-##-#####-#####", 
  "CO": "+57(###)###-####", 
  "CR": "+506-####-####", 
  "CU": "+53-#-###-####", 
  "CV": "+238(###)##-##", 
  "CW": "+599-###-####", 
  "CY": "+357-##-###-###", 
  "CZ": "+420(###)###-###", 
  "DE": "+49(####)###-####", 
  "DE": "+49(###)###-####", 
  "DE": "+49(###)##-####", 
  "DE": "+49(###)##-###", 
  "DE": "+49(###)##-##", 
  "DE": "+49-###-###", 
  "DJ": "+253-##-##-##-##", 
  "DK": "+45-##-##-##-##", 
  "DM": "+1(767)###-####", 
  "DO": "+1(809)###-####", 
  "DO": "+1(829)###-####", 
  "DO": "+1(849)###-####", 
  "DZ": "+213-##-###-####", 
  "EC": "+593-##-###-####", 
  "EC": "+593-#-###-####", 
  "EE": "+372-####-####", 
  "EE": "+372-###-####", 
  "EG": "+20(###)###-####", 
  "ER": "+291-#-###-###", 
  "ES": "+34(###)###-###", 
  "ET": "+251-##-###-####", 
  "FI": "+358(###)###-##-##", 
  "FJ": "+679-##-#####", 
  "FK": "+500-#####", 
  "FM": "+691-###-####", 
  "FO": "+298-###-###", 
  "FR": "+262-#####-####", 
  "FR": "+33(###)###-###", 
  "FR": "+508-##-####", 
  "FR": "+590(###)###-###", 
  "GA": "+241-#-##-##-##", 
  "GD": "+1(473)###-####", 
  "GE": "+995(###)###-###", 
  "GF": "+594-#####-####", 
  "GH": "+233(###)###-###", 
  "GI": "+350-###-#####", 
  "GL": "+299-##-##-##", 
  "GM": "+220(###)##-##", 
  "GN": "+224-##-###-###", 
  "GQ": "+240-##-###-####", 
  "GR": "+30(###)###-####", 
  "GT": "+502-#-###-####", 
  "GU": "+1(671)###-####", 
  "GW": "+245-#-######", 
  "GY": "+592-###-####", 
  "HK": "+852-####-####", 
  "HN": "+504-####-####", 
  "HR": "+385-##-###-###", 
  "HT": "+509-##-##-####", 
  "HU": "+36(###)###-###", 
  "ID": "+62(8##)###-####", 
  "ID": "+62-##-###-##", 
  "ID": "+62-##-###-###", 
  "ID": "+62-##-###-####", 
  "ID": "+62(8##)###-###", 
  "ID": "+62(8##)###-##-###", 
  "IE": "+353(###)###-###", 
  "IL": "+972-5#-###-####", 
  "IL": "+972-#-###-####", 
  "IN": "+91(####)###-###", 
  "IO": "+246-###-####", 
  "IQ": "+964(###)###-####", 
  "IR": "+98(###)###-####", 
  "IS": "+354-###-####", 
  "IT": "+39(###)####-###", 
  "JM": "+1(876)###-####", 
  "JO": "+962-#-####-####", 
  "JP": "+81-##-####-####", 
  "JP": "+81(###)###-###", 
  "KE": "+254-###-######", 
  "KG": "+996(###)###-###", 
  "KH": "+855-##-###-###", 
  "KI": "+686-##-###", 
  "KM": "+269-##-#####", 
  "KN": "+1(869)###-####", 
  "KP": "+850-191-###-####", 
  "KP": "+850-##-###-###", 
  "KP": "+850-###-####-###", 
  "KP": "+850-###-###", 
  "KP": "+850-####-####", 
  "KP": "+850-####-#############", 
  "KR": "+82-##-###-####", 
  "KW": "+965-####-####", 
  "KY": "+1(345)###-####", 
  "KZ": "+7(6##)###-##-##", 
  "KZ": "+7(7##)###-##-##", 
  "LA": "+856(20##)###-###", 
  "LA": "+856-##-###-###", 
  "LB": "+961-##-###-###", 
  "LB": "+961-#-###-###", 
  "LC": "+1(758)###-####", 
  "LI": "+423(###)###-####", 
  "LK": "+94-##-###-####", 
  "LR": "+231-##-###-###", 
  "LS": "+266-#-###-####", 
  "LT": "+370(###)##-###", 
  "LU": "+352(###)###-###", 
  "LV": "+371-##-###-###", 
  "LY": "+218-##-###-###", 
  "LY": "+218-21-###-####", 
  "MA": "+212-##-####-###", 
  "MC": "+377(###)###-###", 
  "MC": "+377-##-###-###", 
  "MD": "+373-####-####", 
  "ME": "+382-##-###-###", 
  "MG": "+261-##-##-#####", 
  "MH": "+692-###-####", 
  "MK": "+389-##-###-###", 
  "ML": "+223-##-##-####", 
  "MM": "+95-##-###-###", 
  "MM": "+95-#-###-###", 
  "MM": "+95-###-###", 
  "MN": "+976-##-##-####", 
  "MO": "+853-####-####", 
  "MP": "+1(670)###-####", 
  "MQ": "+596(###)##-##-##", 
  "MR": "+222-##-##-####", 
  "MS": "+1(664)###-####", 
  "MT": "+356-####-####", 
  "MU": "+230-###-####", 
  "MV": "+960-###-####", 
  "MW": "+265-1-###-###", 
  "MW": "+265-#-####-####", 
  "MX": "+52(###)###-####", 
  "MX": "+52-##-##-####", 
  "MY": "+60-##-###-####", 
  "MY": "+60(###)###-###", 
  "MY": "+60-##-###-###", 
  "MY": "+60-#-###-###", 
  "MZ": "+258-##-###-###", 
  "NA": "+264-##-###-####", 
  "NC": "+687-##-####", 
  "NE": "+227-##-##-####", 
  "NF": "+672-3##-###", 
  "NG": "+234(###)###-####", 
  "NG": "+234-##-###-###", 
  "NG": "+234-##-###-##", 
  "NG": "+234(###)###-####", 
  "NI": "+505-####-####", 
  "NL": "+31-##-###-####", 
  "NO": "+47(###)##-###", 
  "NP": "+977-##-###-###", 
  "NR": "+674-###-####", 
  "NU": "+683-####", 
  "NZ": "+64(###)###-###", 
  "NZ": "+64-##-###-###", 
  "NZ": "+64(###)###-####", 
  "OM": "+968-##-###-###", 
  "PA": "+507-###-####", 
  "PE": "+51(###)###-###", 
  "PF": "+689-##-##-##", 
  "PG": "+675(###)##-###", 
  "PH": "+63(###)###-####", 
  "PK": "+92(###)###-####", 
  "PL": "+48(###)###-###", 
  "PS": "+970-##-###-####", 
  "PT": "+351-##-###-####", 
  "PW": "+680-###-####", 
  "PY": "+595(###)###-###", 
  "QA": "+974-####-####", 
  "RE": "+262-#####-####", 
  "RO": "+40-##-###-####", 
  "RS": "+381-##-###-####", 
  "RU": "+7(###)###-##-##", 
  "RW": "+250(###)###-###", 
  "SA": "+966-5-####-####", 
  "SA": "+966-#-###-####", 
  "SB": "+677-###-####", 
  "SB": "+677-#####", 
  "SC": "+248-#-###-###", 
  "SD": "+249-##-###-####", 
  "SE": "+46-##-###-####", 
  "SG": "+65-####-####", 
  "SH": "+290-####", 
  "SH": "+290-####", 
  "SI": "+386-##-###-###", 
  "SK": "+421(###)###-###", 
  "SL": "+232-##-######", 
  "SM": "+378-####-######", 
  "SN": "+221-##-###-####", 
  "SO": "+252-##-###-###", 
  "SO": "+252-#-###-###", 
  "SO": "+252-#-###-###", 
  "SR": "+597-###-####", 
  "SR": "+597-###-###", 
  "SS": "+211-##-###-####", 
  "ST": "+239-##-#####", 
  "SV": "+503-##-##-####", 
  "SX": "+1(721)###-####", 
  "SY": "+963-##-####-###", 
  "SZ": "+268-##-##-####", 
  "TC": "+1(649)###-####", 
  "TD": "+235-##-##-##-##", 
  "TG": "+228-##-###-###", 
  "TH": "+66-##-###-####", 
  "TH": "+66-##-###-###", 
  "TJ": "+992-##-###-####", 
  "TK": "+690-####", 
  "TL": "+670-###-####", 
  "TL": "+670-77#-#####", 
  "TL": "+670-78#-#####", 
  "TM": "+993-#-###-####", 
  "TN": "+216-##-###-###", 
  "TO": "+676-#####", 
  "TR": "+90(###)###-####", 
  "TT": "+1(868)###-####", 
  "TV": "+688-90####", 
  "TV": "+688-2####", 
  "TW": "+886-#-####-####", 
  "TW": "+886-####-####", 
  "TZ": "+255-##-###-####", 
  "UA": "+380(##)###-##-##", 
  "UG": "+256(###)###-###", 
  "UK": "+44-##-####-####", 
  "US": "+1(###)###-####",
  "UY": "+598-#-###-##-##", 
  "UZ": "+998-##-###-####", 
  "VA": "+39-6-698-#####", 
  "VC": "+1(784)###-####", 
  "VE": "+58(###)###-####", 
  "VG": "+1(284)###-####", 
  "VI": "+1(340)###-####", 
  "VN": "+84-##-####-###", 
  "VN": "+84(###)####-###", 
  "VU": "+678-##-#####", 
  "VU": "+678-#####", 
  "WF": "+681-##-####", 
  "WS": "+685-##-####", 
  "YE": "+967-###-###-###", 
  "YE": "+967-#-###-###", 
  "YE": "+967-##-###-###", 
  "ZA": "+27-##-###-####", 
  "ZM": "+260-##-###-####", 
  "ZW": "+263-#-######" 
}