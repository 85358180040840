import { toast } from 'react-toastify'
import { getToken, obterRefreshToken } from '@utils'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AlertCircle, LogIn, LogOut } from 'react-feather'
import CustomToast from '../components/CustomToast'

const MySwal = withReactContent(Swal)

const API_URL = `${process.env.REACT_APP_API_URL}`


export const renovarToken = async () => {
  const refreshToken = jwt.getRefreshToken();

  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
    }
  };
  return axios.post(jwt.jwtConfig.refreshEndpoint, `"${refreshToken}"`, axiosConfig)
}

export const obterApi = (url) => {

  const Api = axios.create({ baseURL: `${url}` })

  Api.interceptors.request.use(
    async (config) => {
      config.baseURL = `${url}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  Api.interceptors.request.use(async (config) => {
    return new Promise((resolve) => setTimeout(() => resolve(config), 200))
  })

  Api.interceptors.request.use(async (config) => {
    const token = jwt.getToken() ?? false
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  })

  Api.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {

      const { config, response } = error
      const originalRequest = config

      if (response && response.status === 401) {
        try {
          store.dispatch(handleSkin('light'))
          store.dispatch(handleLogout())
          window.location.href = '/login';
          return false
        } catch (error) {
          store.dispatch(handleSkin('light'))
          store.dispatch(handleLogout())
          window.location.href = '/login';
          return false
        }
      }
      return Promise.reject(error)
    }
  );

  return Api
}

const setHeaders = (content_type, check) => {
  const headers = new Headers()

  if (content_type !== '') headers.append('Content-Type', content_type)

  if (check) headers.append('Authorization', `Bearer ${getToken()}`)

  return headers
}


const handleAlertLogoff = async () => {
  return MySwal.fire({
    title: '<strong>Sua sessão expirou!</strong>',
    text: 'Deseja continuar no IntegraDoor?',
    iconHtml: <AlertCircle size={80} />,
    icon: 'info',
    padding: '4px 0 43px 0',
    width: '512px',
    showCancelButton: true,
    confirmButtonText: (
      <span className='align-middle'>
        <LogIn className='me-50' size={15} />
        <span className='align-middle'> Permanecer</span>
      </span>
    ),
    cancelButtonText: (
      <span className='align-middle'>
        <LogOut className='me-50' size={15} />
        <span className='align-middle'> Sair</span>
      </span>
    ),
    customClass: {
      confirmButton: 'btn btn-primary ms-1',
      cancelButton: 'btn btn-outline-primary'
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    allowEscapeKey: false
  }).then(function (result) {
    if (result.value) {
      obterRefreshToken()
    } else {
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      window.location.href = '/login'
    }
  })
}

const handleAlertMoreThanOneLogOn = async () => {
  return MySwal.fire({
    title: '<strong>Atenção!</strong>',
    text: 'Observamos que sua conta foi acessada de mais de um local simultaneamente. Para garantir a segurança de seus dados e manter a integridade do sistema, você será desconectado dessa sessão.',
    iconHtml: <AlertCircle size={80} />,
    icon: 'warning',
    padding: '4px 0 43px 0',
    width: '512px',
    showCancelButton: false,
    confirmButtonText: (
      <span className='align-middle'>
        <LogIn className='me-50' size={15} />
        <span className='align-middle'> Ok</span>
      </span>
    ),
    customClass: {
      confirmButton: 'btn btn-primary ms-1'
    },
    buttonsStyling: false,
    allowOutsideClick: false,
    allowEscapeKey: false
  }).then(function (result) {
    if (result.value) {
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      window.location.href = '/login'
    }
  })
}

const getResp = async (label, resp) => {
  // const history = useHistory()
  if (resp) {
    if (resp.status !== 204) {
      const json = await resp.json()
      if (resp.status > 300) {

        if (resp.status === 401) { // Não autorizado
          handleAlertLogoff()
          return
        }


        if (resp.status === 500) {
          return toast.error(
            <CustomToast
              title='Erro!'
              message='Ocorreu um erro inesperado, favor tentar novamente ou contactar o suporte!'
            />,
            { autoClose: true, toastId: 'toastDefaultError' }
          )
        }

        if (json && json.mensagens && json.mensagens !== '') {

          if (!toast.isActive('toastDefaultError')) {
            toast.error(
              <CustomToast
                title='Erro!'
                message={`${json.mensagens}`}
              />,
              { autoClose: true, toastId: 'toastDefaultError' }
            )
          } else {
            toast.update(
              <CustomToast
                title='Erro!'
                message={`${json.mensagens}`}
              />,
              { autoClose: true, toastId: 'toastDefaultError' }
            )
          }

          if (json.ValidationErrors) {
            return json
          }
        }
      } else if (json) return json
    } else {
      return { message: 'success' }
    }
  }
  return null
}

export const getReq = async (label, url, check) => {
  if (navigator.onLine) {
    try {
      const headers = setHeaders('application/json, text/plain', check)

      const reqParams = {
        method: 'GET',
        headers
      }

      const url_completa = `${API_URL}${url}`

      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) { // Não autorizado
        handleAlertLogoff()
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.dir(e)
      console.log(`Erro ao carregar ${label}: ${e}`)
    }
  } else {
    toast.error(
      <CustomToast
        title='Erro!'
        message='Sem conexão com a Internet, favor tentar novamente ou contactar o suporte!'
      />,
      { autoClose: true, toastId: 'toastError' }
    )
  }
  return null
}

export const postReq = async (label, url, body, check) => {
  if (navigator.onLine) {
    try {
      const headers = setHeaders('application/json, text/plain', check)

      const reqParams = {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      }

      const url_completa = `${API_URL}${url}`

      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) { // Não autorizado
        handleAlertLogoff()
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.log(`Erro ao criar ${label}: ${e}`)
    }
  } else {
    toast.error(
      <CustomToast
        title='Erro!'
        message='Sem conexão com a Internet, favor tentar novamente ou contactar o suporte!'
      />,
      { autoClose: true, toastId: 'toastError' }
    )
  }
  return null
}

export const putReq = async (label, url, body, check) => {
  if (navigator.onLine) {
    try {
      const headers = setHeaders('application/json, text/plain', check)

      const reqParams = {
        method: 'PUT',
        headers,
        body
      }

      const url_completa = `${API_URL}${url}`
      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) { // Não autorizado
        handleAlertLogoff()
        return
      }


      return await getResp(label, resp)
    } catch (e) {
      console.warn(`Erro ao atualizar ${label}: ${e}`)
    }
  } else {
    toast.error(
      <CustomToast
        title='Erro!'
        message='Sem conexão com a Internet, favor tentar novamente ou contactar o suporte!'
      />,
      { autoClose: true, toastId: 'toastError' }
    )
  }
}

export const deleteReq = async (label, url, check) => {
  if (navigator.onLine) {
    try {
      const headers = setHeaders('application/json, text/plain', check)

      const reqParams = {
        method: 'DELETE',
        headers
      }

      const url_completa = `${API_URL}${url}`
      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) { // Não autorizado
        handleAlertLogoff()
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.warn(`Erro ao remover ${label}: ${e}`)
    }
  } else {
    toast.error(
      <CustomToast
        title='Erro!'
        message='Sem conexão com a Internet, favor tentar novamente ou contactar o suporte!'
      />,
      { autoClose: true, toastId: 'toastError' }
    )
  }
  return null
}

export const sendFile = async (label, url, body, check) => {
  if (navigator.onLine) {
    try {
      const headers = setHeaders('', check)

      const reqParams = {
        method: "POST",
        headers,
        body
      }

      const url_completa = `${API_URL}${url}`
      const resp = await fetch(url_completa, reqParams)
      if (resp.status === 401) { // Não autorizado
        handleAlertLogoff()
        return
      }

      return await getResp(label, resp)
    } catch (e) {
      console.warn(`Erro ao enviar imagem do ${label}: ${e} `)
    }
  }
}