// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SessionApi } from '@services/api-routes'

// ** Axios Imports
import axios from 'axios'
import { getSessionTasks, getSessionChatContacts, selectSessionChat, sendSessionMsg } from '../../../../services/Chat'

// export const atualizar = createAsyncThunk('empresa/atualizar', async (empresa, { dispatch }) => {
//   const result = await atualizarEmpresa(empresa)
//   await dispatch(obterEmpresasUnidadesPorUsuario())
//   return result.data
// })

export const getUserProfile = createAsyncThunk('appChat/profile', async (sessionResourcesId, userId) => {
  console.log('chegouuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu', sessionResourcesId)
  const response = await SessionApi.chat.getSessionTasks(sessionResourcesId, userId) // getSessionTasks(sessionResourcesId, userId) // await axios.get('/apps/chat/users/profile-user')
  console.log('&&&&&&&&&&&&&&&&&&&', response)
  return response
})

export const getContacts = createAsyncThunk('appChat/contacts', async (sessionResourcesId) => {
  const response = await SessionApi.chat.getSessionChatContacts(sessionResourcesId) // getSessionChatContacts(sessionResourcesId) // axios.get('/apps/chat/chats-and-contacts')
  console.log('&&&&&&&&&&&&&&&&&&&', response)
  return response
})

export const getChats = createAsyncThunk('appChat/chats', async (sessionResourcesId) => {
  const response = await SessionApi.chat.getSessionChatContacts(sessionResourcesId) // getSessionChatContacts(sessionResourcesId) // axios.get('/apps/chat/chats-and-contacts')
  console.log('&&&&&&&&&&&&&&&&&&&', response)
  return response
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
  const response = await SessionApi.chat.selectSessionChat(id) // selectSessionChat(id) // axios.get('/apps/chat/get-chat', { id })
  await dispatch(getContacts())
  console.log('&&&&&&&&&&&&&&&&&&&', response)
  return response
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  const response = await SessionApi.chat.sendSessionMsg(obj) // sendSessionMsg() // axios.post('/apps/chat/send-msg', { obj })
  await dispatch(selectChat(obj.contact.id))
  console.log('&&&&&&&&&&&&&&&&&&&', response)
  return response
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        console.log('state getUserProfile', state)
        console.log('action getUserProfile', action)
        state.userProfile = action.payload.payload
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        console.log('state getContacts', state)
        console.log('action getContacts', action)
        state.contacts = action.payload.payload
      })
      .addCase(getChats.fulfilled, (state, action) => {
        console.log('state getContacts', state)
        console.log('action getContacts', action)
        state.chats = action.payload.payload
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedUser = action.payload.payload
      })
  }
})

export default appChatSlice.reducer
