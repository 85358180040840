import { Fragment } from 'react'

type CustomToastType = {
  title: string,
  message: string
}

const CustomToast = ({title, message}: CustomToastType) => {

  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <h6 className='toast-title fw-bold ms-0'>{title}</h6>
        </div>
      </div>
      {message && <span>{message}</span>}
    </Fragment>
  )
}

export default CustomToast;